@import '../../../styles/variables';
@import '~bootstrap/scss/mixins';

.search__not-found {
  min-height: 90px;
  text-align: center;
  padding: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: black;
  overflow-x: hidden;
}

.search-select__dropdown {
  &--item {
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
      color: $primary;
      background-color: rgba($color: $primary, $alpha: 0.1); // 0.5
    }
  }
}
